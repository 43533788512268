import { appSyncQuery } from './index';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  MerchantOnboarding,
  MerchantOnboardingInput,
  DocumentInput,
} from '../GraphQL/internal_types';

const getString = `query MerchantOnboarding($merchant_uid: ID!) {
  merchantOnboarding(merchant_uid: $merchant_uid) {
    banks {
      account_number
      account_type
      account_uses
      bank_code
      bank_name
      institution_number
      name
      transit_number
      __typename
    }
    business {
      additional_underwriting_data {
        annual_ach_volume
        average_ach_transfer_amount
        average_card_transfer_amount
        business_description
        card_volume_distribution {
          card_present_percentage
          ecommerce_percentage
          __typename
        }
        credit_check_allowed
        credit_check_ip_address
        credit_check_timestamp
        credit_check_user_agent
        merchant_agreement_accepted
        merchant_agreement_ip_address
        merchant_agreement_timestamp
        merchant_agreement_user_agent
        statement_descriptor
        refund_policy
        volume_distribution_by_business_type {
          business_to_business_volume_percentage
          business_to_consumer_volume_percentage
          other_volume_percentage
          __typename
        }
        __typename
      }
      underwriting_data {
        annual_card_volume
        business_address {
          city
          country
          line1
          line2
          postal_code
          region
          __typename
        }
        has_accepted_credit_cards_previously
        business_name
        business_type
        delivery_0_days_percent
        delivery_1_to_7_days_percent
        delivery_8_to_14_days_percent
        delivery_15_to_30_days_percent
        delivery_over_30_days_percent
        doing_business_as
        incorporation_date
        incorporation_state
        max_transaction_amount
        mcc
        phone
        prod_serv_sold
        ownership_type
        tax_id
        url
        __typename
      }
      __typename
    }
    business_owners {
      address {
        city
        country
        line1
        line2
        postal_code
        region
        __typename
      }
      dob
      email
      first_name
      last_name
      middle_name
      nationality
      owner_position
      percentage_ownership
      phone
      primary_owner
      tax_id
      title
      uid
      __typename
    }
    country_code_of_operation
    is_locked
    merchant_uid
    needs_docs
    ticket_id
    user_email
    user_full_name
    __typename
  }
}`;

export const get = (
  merchant_uid: string,
): Promise<GraphQLResult<{ merchantOnboarding: MerchantOnboarding }>> => {
  const variables = {
    merchant_uid,
  };
  return appSyncQuery(getString, variables, true);
};

const updateString = `mutation CreateMerchantOnboarding(
  $merchant_onboarding: MerchantOnboardingInput!
  $support_note: SupportNoteInput
  $is_form_completed: Boolean!
) {
  createMerchantOnboarding(
    merchant_onboarding: $merchant_onboarding
    support_note: $support_note
    is_form_completed: $is_form_completed
  )
}`;

export const update = (
  merchant_onboarding: MerchantOnboardingInput,
  is_form_completed: boolean,
  docs: DocumentInput[] | null,
): Promise<GraphQLResult<{ createMerchantOnboarding: boolean }>> => {
  const variables = {
    merchant_onboarding,
    is_form_completed,
    docs,
  };
  return appSyncQuery(updateString, variables, true);
};

const uploadOnboardingDocumentString = `mutation CreateSupportNote(
  $merchant_uid: ID!
  $ticket_id: ID!
  $support_note: SupportNoteInput!
) {
  createSupportNote(
    merchant_uid: $merchant_uid
    ticket_id: $ticket_id
    support_note: $support_note
  )
}`;

export const uploadOnboardingDocument = (
  merchant_uid: string,
  ticket_id: number,
  docs: DocumentInput[],
): Promise<GraphQLResult<{ createSupportNote: boolean }>> => {
  const variables = {
    merchant_uid,
    ticket_id: ticket_id.toString(),
    docs,
  };
  return appSyncQuery(uploadOnboardingDocumentString, variables, true);
};
